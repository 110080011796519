import { getApiBaseUrl, getApiClient } from "./serviceconfig";
var apiBaseUrl = getApiBaseUrl();

export async function createDirectInvoice(cookies, inputs) {
    return getApiClient(cookies)
    .post(`${apiBaseUrl}/direct_invoices/`, inputs)
    .then(response => response.data)
    }

export async function getDirectInvoiceDetails(cookies, invoiceId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/direct_invoices/${invoiceId}/`)
                .then(response => response.data)
    }

export function deleteDirectInvoice(cookies, invoiceId) {
    return getApiClient(cookies).delete(`${apiBaseUrl}/direct_invoices/${invoiceId}/`)
    }

export function cancelDirectInvoice(cookies, invoiceId) {
    return getApiClient(cookies).patch(`${apiBaseUrl}/direct_invoices/${invoiceId}/`)
    }

export async function updateDirectInvoice(cookies, inputs,  invoiceId) {
    return getApiClient(cookies)
    .put(`${apiBaseUrl}/direct_invoices/${invoiceId}/`,inputs)
    .then(response => response.data)
    }
    

    
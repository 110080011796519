import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getAllSalesOrder(cookies,searchString) {

  let url= `${apiBaseUrl}/sales_orders/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
 return getApiClient(cookies).get(url)
  .then(response => response.data);
}
export async function getSalesOrdersForWorkSchedule(cookies) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/sales_orders/work_schedule/`)
  .then(response => response.data);
}

export async function getSalesOrdersForInvoice(cookies,plant_id,id) {
  console.log(plant_id,id+"plant_id+id")
  let url= `${apiBaseUrl}/sales_orders/invoice/`;
  if (plant_id && id) {
    url = url + `?plant_id=${plant_id}&invoice_id=${id}`;
  }
  else  {
    url = url + `?plant_id=${plant_id}`;
  }
  return getApiClient(cookies).get(url)
  .then(response => response.data);
}

export async function getSalesOrdersForDirectInvoice(cookies,invoice_date,id) {
  console.log(invoice_date+"invoice_date");
  let url= `${apiBaseUrl}/sales_orders/direct_invoice/`;
  if (id && invoice_date){
    url = url+`?invoice_date=${invoice_date}&invoice_id=${id}`;
  }
  else{
    url = url + `?invoice_date=${invoice_date}`;
  }
  return getApiClient(cookies).get(url)
  .then(response => response.data);
}

export async function getSalesOrdersForDesignMix(cookies) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/sales_orders/design_mix/`)
  .then(response => response.data);
}


export async function getSalesOrderNumber(cookies,dateString, company_id) {

  let url= `${apiBaseUrl}/sales_orders/sales_order_number/`;
  if (dateString) {
    //url = url + `?sales_order_date=${dateString}&company_id=${company_id}`;
  }
 return getApiClient(cookies).get(url)
  .then(response => response.data);
  
  
}

export function createSalesOrder(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/sales_orders/`, inputs,{
    headers: {
      "Content-Type": "multipart/form-data",
  }});
  
  
}

export function updateSalesOrder(cookies, inputs, soId) {

  return getApiClient(cookies).put(`${apiBaseUrl}/sales_orders/${soId}/`,inputs,{
  headers: {
    "Content-Type": "multipart/form-data",
}});

}

export async function getSalesOrderDetails(cookies, soId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/sales_orders/${soId}/`)
      
  .then(response => response.data)
}

export function deleteSalesOrder(cookies, soId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/sales_orders/${soId}/`)
}

export async function printSalesOrder(cookies, soId) {
  return getApiClient(cookies).get(`${apiBaseUrl}/sales_orders/${soId}/print_so/`)
              .then(response => response.data)
}

export async function printProformaInvoice(cookies, soId) {
  return getApiClient(cookies).get(`${apiBaseUrl}/sales_orders/${soId}/print_proforma_invoice/`)
              .then(response => response.data)
}
function TableRowsDirectInvoice({rowsData,handleChangeTableDetails}) {
    console.log(JSON.stringify(rowsData)+"rowsdata TableRowsSalesPosting")
    return(
        rowsData.map((data, index)=>{
            const {id,product,unit,ordered_qty,balance_qty,invoice_qty,tax,basic_rate,taxable_amt,cgst_amt,sgst_amt,igst_amt,gst_amt,gross_amt}=data
        return(
            <tr key={index}>
                <td  style={{display:"None"}}>
                    {id}
                </td>
                <td>
                    {product} 
                </td> 
                <td>
                    {unit}
                </td>        
                <td>
                    {ordered_qty}
                </td>
                <td>
                    {balance_qty}
                </td>
                <td>
                    <input type="number" step="any" min="0" style={{width: "100px",textAlign:"right"}} className="form-control"  id="invoice_qty" value={invoice_qty||0} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="invoice_qty" required />
                </td>
                <td>
                    {basic_rate}
                </td>
                <td>
                    {taxable_amt}
                </td>
                <td>
                    {tax}
                </td>
                <td>
                    {cgst_amt}
                </td>
                <td>
                    {sgst_amt}
                </td>
                <td>
                    {igst_amt}
                </td>
                <td>
                    {gst_amt}
                </td>
                <td>
                    {gross_amt}
                </td>
            </tr>
            )
        })
    )
    }
    export default TableRowsDirectInvoice;